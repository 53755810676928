






































































































@import '/src/assets/scss/variables/_variables';

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.rooms-container > li {
  border-radius: 5px;
  margin: 0 0 1rem 0;
  overflow: hidden;


  .room-header {
    padding: 0.6rem 1.2rem;
    color: #444;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);

    .dark-layout & {
      background-color: rgba(255, 255, 255, 0.1);
      color: #ddd;
      border-color: #283046;
    }
  }

  .beds-container {
    padding: 1rem 0 0 1rem;
    background-color: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 2px 2px rgb(0,0,0,0.1);

    .dark-layout & {
      background-color: rgba(255, 255, 255, 0.04);
    }

    > .bed {
      margin: 0 1rem 1rem 0;
      padding: 0;
      list-style-type: none;
      width: 112px;
      text-align: center;
      color: #444;

      > a, > a:hover > a:visited {
        color: inherit;
        text-decoration: none;
      }

      > a {
        height: 100%;
        border-radius: 5px;
        border-width: 40px 0 0 0;
        border-style: solid;
        border-color: #fff;
        display: block;
        position: relative;
        background-color: #fff;
        .dark-layout & {
          background-color: #f8f8f8;
        }
      }

      .photo {
        border-radius: 50px;
        background: #fff;
        border: 2px solid #aaa;
        box-sizing: border-box;
        display: block;
        height: 52px;
        width: 52px;
        position: absolute;
        top: -26px;
        left: 50%;
        transform: translateX(-50%);

        .dark-layout & {
          background-color: #f8f8f8;
        }
      }

      .bed-content {
        padding: 2.5rem 0 1rem;
        width: 100%;
      }

    }

    > .bed-on > a {
      border-color: #9be59e;

      .photo {
        border-color: #9be59e;
      }
    }

    > .bed-exit > a {
      border-color: #f89e97;

      .photo {
        border-color: #f89e97;
      }
    }

    > .bed-offline > a {
      border-color: #999;

      .photo {
        border-color: #999;
      }
    }

    > .bed-silent > a {
      opacity: 0.6;
    }
  }

}

.icon {
  height: 26px;
}

.slash-bell {
  width: 18px;
}

.time-height {
  height: 24px;
}
