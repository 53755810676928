


















































.row-item {
  vertical-align: center;
  line-height: 40px;

  .icon {
    width: 26px;
    margin-left: 5px;
  }
}

