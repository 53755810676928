



































































.info-section {
  border-radius: 5px;
  background-color: rgba(0,0,0,0.08);
  border: 1px solid #ccc;
  box-sizing: border-box;
  .dark-layout & {
    background-color: rgba(255,255,255,0.08);
    border-color: #555;

  }
}
